.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 100%;
  max-width: 480px;
  max-height: 420px;

  > img {
    width: 33%;
    margin-bottom: 1em; }

  > div {
    margin-top: 1em;

    &:first-child {
      margin-top: 0; }

    &:last-child {
      margin-top: 2em; } } }
