.section {
  margin-top: 2em;
  margin-bottom: 6em;

  @media only screen and (max-width: 960px) {
    margin-bottom: 4em; } }

.header {
  display: block;

  @media only screen and (max-width: 960px) {
    display: inline; } }

.actionIconButton {
  display: none !important;
  visibility: hidden;
  vertical-align: initial !important;

  @media only screen and (max-width: 960px) {
    display: inline-flex !important;
    visibility: visible; } }

.actionButton {
  display: block;
  visibility: visible;
  margin-top: 1em !important;

  @media only screen and (max-width: 960px) {
    display: none !important;
    visibility: hidden; } }
