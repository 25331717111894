.homeLink {
  height: 2em;
  margin-right: 2em;

  cursor: pointer;
  outline: none;
  border: none; }

.menuHeader {
  padding: 0 1em;
  color: #777; }
